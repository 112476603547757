import rchloCardBg from 'utils/assets/images/png/rchlo-card-bg.png';
import { styled } from 'utils/styles';

// TODO: remover verificação da feature flag quando a feature for efetivada
const featureFlagIsInative =
  !process.env.REACT_APP_FF_UNIFIED_CARD_FORM || process.env.REACT_APP_FF_UNIFIED_CARD_FORM === 'false';

export const Wrapper = styled('div', {
  width: '100%',
  bg: 'transparent',
  perspective: '1000px',
  maxWidth: '500px',
});

export const InnerWrapper = styled('div', {
  width: '100%',
  pt: '60%',
  position: 'relative',
  transition: 'transform 500ms ease-out',
  transformStyle: 'preserve-3d',
  variants: {
    flipped: {
      true: {
        transform: 'rotateY(-180deg)',
      },
    },
  },
});

const sideStyles = {
  width: '100%',
  height: '100%',
  padding: '10%',
  position: 'absolute',
  top: '0',
  left: '0',
  br: '8px',
  bg: '$gray65',
  backfaceVisibility: 'hidden',
  transition: 'background-color 250ms ease-out',
};

export const FrontSide = styled('div', {
  ...sideStyles,
  variants: {
    type: {
      'american-express': {
        bg: featureFlagIsInative ? '#1B457E' : '#fff',
      },
      'diners-club': {
        bg: '#fff',
        '& svg': {
          width: 20,
          maxHeight: 20,
        },
      },
      elo: {
        bg: '#525252',
      },
      hipercard: {
        bg: '#B3131B',
      },
      mastercard: {
        bg: '#394F5D',
        '& svg': {
          width: '24px',
        },
      },
      riachuelo: {
        bg: '#495037',
        backgroundImage: `url(${rchloCardBg})`,
      },
      'riachuelo-master': {
        bg: '#495037',
        backgroundImage: `url(${rchloCardBg})`,
      },
      'riachuelo-visa': {
        bg: '#495037',
        backgroundImage: `url(${rchloCardBg})`,
      },
      visa: {
        bg: featureFlagIsInative ? '#3689BE' : '#fff',
      },
    },
  },
});

export const BackSide = styled('div', {
  ...sideStyles,
  transform: 'rotateY(180deg)',
  variants: {
    type: {
      'american-express': {
        bg: featureFlagIsInative ? '#1B457E' : '#fff',
      },
      'diners-club': {
        bg: '#fff',
        '& svg': {
          width: 26,
          maxHeight: 28,
        },
      },
      elo: {
        bg: '#525252',
      },
      hipercard: {
        bg: '#B3131B',
      },
      mastercard: {
        bg: '#394F5D',
        '& svg': {
          width: '24px',
        },
      },
      riachuelo: {
        bg: '#222222',
      },
      'riachuelo-master': {
        bg: '#222222',
      },
      'riachuelo-visa': {
        bg: '#222222',
      },
      visa: {
        bg: featureFlagIsInative ? '#3689BE' : '#fff',
      },
    },
  },
});

export const FrontSideBrand = styled('div', {
  position: 'absolute',
  top: '5%',
  right: '5%',
  '& svg': {
    display: 'inline-flex',
    height: 48,
    width: 48,
  },
  variants: {
    type: {
      'american-express': {
        bg: 'transparent',
      },
      'diners-club': {
        bg: 'transparent',
      },
      elo: {
        bg: 'transparent',
      },
      hipercard: {
        bg: 'transparent',
      },
      mastercard: {
        bg: 'transparent',
      },
      riachuelo: {
        bg: 'transparent',
      },
      'riachuelo-master': {
        bg: 'transparent',
        '& svg': {
          '& g': {
            bg: 'transparent',
          },
        },
      },
      'riachuelo-visa': {
        bg: 'transparent',
        '& svg': {
          '& g': {
            '& path:first-child': {
              fill: 'transparent',
            },
          },
        },
      },
      visa: {
        bg: 'transparent',
      },
      rchlo: {
        bg: 'transparent',
      },
    },
  },
});

export const FrontSideFields = styled('div', {
  width: '80%',
  position: 'absolute',
  bottom: '15%',
  left: '10%',
  display: 'grid',
  gap: '10px',
});

export const FrontSideFieldsRow = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'space-between',
});

export const BackSideMagneticStripe = styled('div', {
  width: '100%',
  height: '20%',
  position: 'absolute',
  top: '10%',
  left: '0',
  bg: '$black',
});

export const SecurityCodeWrapper = styled('div', {
  width: '70%',
  height: '20%',
  paddingRight: '5%',
  position: 'absolute',
  top: '36%',
  left: '0',
  bg: '$gray30',
  display: 'grid',
  justifyContent: 'end',
  alignContent: 'center',
});

export const SecurityCodeContainer = styled('div', {
  border: '3px solid white',
  borderRadius: '100%',
  backgroundColor: 'transparent',
  width: '56px',
  height: '56px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  left: '24px',
});

export const CardNumber = styled('span', {
  typo: 'body2',
  color: '$white',
});

export const HolderName = styled('span', {
  typo: 'body2',
  fontSize: '13px',
  color: '$white',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const ValidThru = styled('span', {
  typo: 'body2',
  color: '$white',
});

export const SecurityCode = styled('span', {
  typo: 'body2',
  color: '$black',
});
